import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: 'https://43ccd9e8418020f8de00d4781042e81a@o367088.ingest.us.sentry.io/5170203',
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration({
                networkDetailAllowUrls: [environment.apiUrl],
            })
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.3,
        replaysOnErrorSampleRate: 1.0
    });

}


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
