<form class="m-form">
	<h1 mat-dialog-title> What is this loan for? </h1>
	<div mat-dialog-content class="m-form__group">
		<div class="row">
			<div class="col-12">
				<mat-form-field class=" mat-form-field-fluid">
					<!-- <mat-select [(ngModel)]="selectedPurposeCode" name="selectedProductName"
								placeholder="Select loan purpose" required>
						<ng-container *ngFor="let purposeCode of purposeCodeList">
							<mat-option [value]="purposeCode">
								{{purposeCode}}
							</mat-option>

						</ng-container>
					</mat-select> -->
					<mat-label>Select loan purpose</mat-label>
					<mat-select [(ngModel)]="selectedPurposeCode" id="selectedPurposeCode"
								name="selectedProductName"
								required>
						<mat-option *ngFor="let purposeCode of purposeCodeList"
									[value]="purposeCode.value" id="{{purposeCode.value}}">{{ purposeCode.label }}
						</mat-option>
					</mat-select>
					<mat-error>Please select what this loan is for.</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12"
				 *ngIf="selectedPurposeCode?.toLowerCase() === 'pp^other'">
				<mat-form-field class=" mat-form-field-fluid">
					<mat-label> Enter Purpose</mat-label>
					<input #otherPurposeCodeModel="ngModel" [(ngModel)]="otherPurposeCode" autocomplete="off" matInput
						   name="otherPurposeCode" type="text" required>
					<mat-error>Please enter the purpose of this loan.</mat-error>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div mat-dialog-actions class="p-4 pt-1">
		<div class="d-flex justify-content-between align-items-center w-100">
			<button mat-dialog-close type="button" class="me-2" mat-stroked-button
					color="primary" name="cancel" id="cancel">Cancel
			</button>
			<mat-bar-button (tsSubmitIfValid)="onSubmit()" [options]="barButtonOptions" name="continue" id="continue"
							matTooltip="No impact to your credit score, continue to check your loan options">
			</mat-bar-button>
		</div>
	</div>
</form>
