import {ApplicationStatusEnum, ApplicationTypeEnum} from '../enums/process-state.enum';
import {CollateralModel} from './collateral.model';
import {ApplicationModel} from './application.model';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {UrlObject} from 'url';
import {ApplicantModel} from './applicant.model';
import {AppConfigService} from '@creditsnap/app-config';
import {SearchModel} from './search.model';

export class KeyIdentifierModel {
	applicationId: number;
	channel: string;
	employeeID: string;
	editPrimaryApplicant: boolean;
	preApprovalApplicationId: number;
	clientApplicationNumber: string;
	customerIdentifier: string;
	campaignType: string; // externalCampaignLoanAmtRateTermsWithCSSoftInquiry OR externalCampaignLoanAmtRateTermsNoSoftInquiry OR externalCampaignWithCSSoftInquiry or externalCampaignWithCSEligibilityRatesNoSoftInquiry
	isApplicationChanged = false; // Applicant add / edit, vehicle
	isProspect = false;
	isOfferGenerated = false;
	isOfferGeneratedWithLTV = false;
	isCreditBureauReportGenerated = false;
	isReadyForOfferGeneration = false;
	isValuationCompleted = false;
	isInvalidVINFromNADA = false;
	isServiceExecutionSuccess: boolean;
	isPrimaryApplicant = false;
	isPrimaryApplicantAdded = false;
	isPrimaryCreditReportAvailable = false;
	isTradelineSelected = false;
	isPayOffReceived = false;
	hasCustomerOptedOffer = false;
	isAllApplicantsAdded = false;
	isCoBorrowerApplication = false;
	isCoBorrowerNeeded = false;
	isCollateralAvailable = false;
	isVINAvailable = false;
	isVehicleYearAvailable = false;
	isFinanceSubmitFailed = false;
	isFinanceSubmitSuccess = false;
	hasCustomerQualifiedForOffer = false;
	offerGeneratedButtonText = 'Generate Offer';
	hasQualifiedRates = false;
	isCashApplication = false;
	isAutoLoanApplication = false;
	isPersonalLoanApplication = false;
	isHomeEquityLoanApplication = false;
	isCreditCardApplication = false;
	isCollateralTradesAvailable = false;
	isAPIChannel = true;
	isPreEligibilityChecked = false;
	applicationStatus: string;
	offerStatus: string;
	offerId: number;
	offerVariationId: number;
	tradeId: number;
	collateralId: number;
	productName: string;
	productType: string;
	loanPurpose: string;
	applicantType: string;
	applicationType: string;
	applicantFirstName: string;
	applicantLastName: string;
	coborrowerFirstName: string;
	coborrowerLastName: string;
	primaryApplicantId: number;
	primaryApplicantAdded: boolean;
	offerCategory: string;
	offerCategoryCode: string;
	optedAch: boolean;
	navigationAction: string;
	screenAction: string;
	onPageLoad: boolean;
	errorCode: string | null;
	errorMessage: string;
	tracker: string;
	application: ApplicationModel;
	primaryApplicant: ApplicantModel | undefined;
	secondApplicant: ApplicantModel | undefined;
	appConfig: any;
	loanPurposeConfig: any;
	// Temporary data for display
	vehicleDescription: string;
	underWritingStatus: string;

	urlObject: UrlObject;
	referralCode: string;
	subId: any;
	newVehicleHasNotMadeUpMind = false;
	selectedImage: string;
	decision: any; // for approve screen data pass
	skipSelectionOfTrade = false; // skip button option for NASA

	provisioningFeatures: any;
	crossSellSkipped = false;
	paymentSkipped = false;
	losStatus: string;
	idVerificationStatus: string;
	loanAgreementStatus: string;
	incomeVerificationStatus: string;
	isVehicleSelected: boolean;
	personalLoanPurposeCode: string;
	documentsStatus: string;
	schedulingDone: boolean;
	applicantsStatus: string;
	sessionId: string;
	status: string;
	promoCode: string;
	achStatus: string;

	financeData: any;
	finalSubmitToCore: boolean;
	merchantName: string;
	noTradelineAvailable: boolean;
	offerGenerationUUID: string;

	isExistingMember: string;
	searchData: SearchModel;
    merchantId:string;
	isConditionalProvision: string;

	constructor(
		loading: boolean,
	) {
		this.primaryApplicantAdded = false;
		this.editPrimaryApplicant = false;
		this.applicationType = 'I';
		this.optedAch = false;
		this.losStatus = null;
		this.onPageLoad = loading;
		this.vehicleDescription = '';
	}

	isApplicationPresent() {
		if (this.applicationId === undefined || this.applicationId === null || this.applicationId === 0) {
			return false;
		}
		console.log('Application exists =>', this.applicationId);
		return true;
	}

	setOfferStatus(status: string) {
		this.isOfferGenerated = (status === ApplicationStatusEnum.OFFER_GENERATED ||
			status === ApplicationStatusEnum.OFFER_GENERATED_WITH_LTV || status === ApplicationStatusEnum.OFFER_GENERATION_INITIATED);
		this.isReadyForOfferGeneration = !this.isOfferGenerated;
	}

	setLoanPurpose(loanPurpose: string) {
		this.loanPurpose = loanPurpose;
		this.loanPurposeConfig = AppConfigService.config.loanPurpose[loanPurpose];
		this.identifyProductType(loanPurpose);
	}

	setCollateralAddedStatus(status: string) {
		if (this.loanPurposeConfig.tradeLine.required) {
			this.isTradelineSelected = (status === ApplicationStatusEnum.TRADELINE_SELECTED
				|| status === ApplicationStatusEnum.COLLATERAL_TRADELINE_ADDED);
			// TODO: clean up later
			if (!this.isTradelineSelected) {
				this.isTradelineSelected = (status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED ||
					status === ApplicationStatusEnum.COLLATERAL_ADDED);
			} else {
				this.isCollateralTradesAvailable = this.isTradelineSelected;
			}
		}
		this.isCollateralAvailable = (status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED ||
			status === ApplicationStatusEnum.COLLATERAL_ADDED ||
			status === ApplicationStatusEnum.COLLATERAL_PROPERTY_UPDATE);
		this.isValuationCompleted = (status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED ||
			status === ApplicationStatusEnum.COLLATERAL_PROPERTY_UPDATE ||
		(status === ApplicationStatusEnum.COLLATERAL_ADDED && this.loanPurposeConfig.productName === 'AUTO_SPECIALTY'));
		this.offerGeneratedButtonText = this.isOfferGenerated ? 'Re-Generate Offer' : 'Generate Offer';
	}

	setOfferQualifiedStatus(status: string = this.applicationStatus) {
		// Offer is generated and application status is no offer then not qualified otherwise, qualified.
		this.hasCustomerQualifiedForOffer = this.isOfferGenerated && !(status === ApplicationStatusEnum.NO_OFFER_FOUND
			|| this.applicationStatus === ApplicationStatusEnum.NO_BUREAU_REPORT);
	}

	updateOfferStatus(appOfferStatus: string) {
		if (appOfferStatus === undefined || appOfferStatus === null) {
			this.isOfferGenerated = false;
			return;
		}
		this.offerStatus = appOfferStatus;
		this.isOfferGenerated = (appOfferStatus === ApplicationStatusEnum.NO_OFFER_FOUND ||
			appOfferStatus === ApplicationStatusEnum.NO_OFFER_PEND ||
			appOfferStatus === ApplicationStatusEnum.NO_BUREAU_REPORT ||
			appOfferStatus === ApplicationStatusEnum.OFFER_GENERATED ||
			appOfferStatus === ApplicationStatusEnum.OFFER_SELECTED ||
			appOfferStatus === ApplicationStatusEnum.NO_OFFER_FRAUD_DECLINE ||
			appOfferStatus === ApplicationStatusEnum.FRAUD ||
			appOfferStatus === ApplicationStatusEnum.NO_OFFER_HARD_DECLINE ||
			appOfferStatus === ApplicationStatusEnum.NO_OFFER_SOFT_DECLINE ||
			appOfferStatus === ApplicationStatusEnum.OFFER_GENERATED_WITH_LTV  ||
			appOfferStatus === ApplicationStatusEnum.OFFER_GENERATION_INITIATED);
		if (this.isOfferGenerated) {
			this.isCreditBureauReportGenerated = true;
		} else {
			this.isCreditBureauReportGenerated = (appOfferStatus === ApplicationStatusEnum.BUREAU_REPORT_RECEIVED);
		}
		this.isReadyForOfferGeneration = !this.isOfferGenerated;
	}

	updateApplicationStatus(status: string) {
		this.applicationStatus = status;
		this.isFinanceSubmitFailed = status === ApplicationStatusEnum.FINANCIAL_SUBMITTED_FAILED;
		this.isFinanceSubmitSuccess = (status === ApplicationStatusEnum.FINANCIAL_SUBMITTED || status === ApplicationStatusEnum.PRE_FINANCIAL_SUBMITTED);
		this.isAllApplicantsAdded = (status === ApplicationStatusEnum.ALL_APPLICANTS_ADDED);
		this.isPrimaryApplicantAdded = (status === ApplicationStatusEnum.PRIMARY_APPLICANT_ADDED) || this.isAllApplicantsAdded;
		if (this.applicationStatus === ApplicationStatusEnum.NO_BUREAU_REPORT ||
			this.applicationStatus === ApplicationStatusEnum.NO_OFFER_FOUND) {
			this.isOfferGenerated = true;
			this.isCreditBureauReportGenerated = true;
			this.hasCustomerQualifiedForOffer = false;
			this.isReadyForOfferGeneration = false;
		} else if (status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED || status === ApplicationStatusEnum.COLLATERAL_ADDED) {
			this.isOfferGenerated = false;
		}
	}

	updateKeyIdentifier(res: ApplicationModel) {
		this.productName = res.loanPurpose;
		this.isAPIChannel = res.channel === 'API';
		console.log('channel =>', this.isAPIChannel, res.channel);
		this.applicationId = res.applicationId;
		this.preApprovalApplicationId = res.preApprovalApplicationId || null;
		this.campaignType = res.campaignType || null;
		this.clientApplicationNumber = res.clientApplicationNumber;
		this.selectedImage = res.selectedImage;
		this.applicantsStatus = res.applicantsStatus;
		this.updateOfferStatus(res.offerStatus);
		this.updateApplicationStatus(res.applicationStatus || res.status);
		if (res.applicants && res.applicants.length > 0) {
			this.updateApplicant(res.applicants);
		}
		if (res.losStatus || res.underWritingStatus || this.decision) {
			this.updateLosStatus(res);
		}
		if (res.referralCode && res.merchantName) {
			this.referralCode = res.referralCode;
			this.merchantId = res.referralCode;
			this.merchantName = res.merchantName;
		}

		this.setApplicationType(res.applicationType);
		this.setLoanPurpose(res.loanPurpose);
		this.setCollateralAddedStatus(res.collateralStatus);
		this.setOfferQualifiedStatus();
		this.setProvisioningStatus(res);
		if (!this.isCashApplication) {
			this.hasCustomerQualifiedForOffer = this.isFinanceSubmitSuccess ?
				this.isFinanceSubmitSuccess : this.hasCustomerQualifiedForOffer;
			this.isOfferGenerated = this.isFinanceSubmitSuccess ? this.isFinanceSubmitSuccess : this.isOfferGenerated;
		}
		console.log('updateKeyIdentifier application data =>', this);
	}

	setApplicationType(applicationType: string = this.applicationType) {
		this.isCoBorrowerApplication = applicationType === ApplicationTypeEnum.JOINT;
		this.applicationType = applicationType;
	}

	updateLosStatus(app: ApplicationModel) {
		this.losStatus = app.losStatus;
		this.underWritingStatus = app.underWritingStatus;
		// if (this.decision) {
		//     this.losStatus = this.decision.decisionStatus;
		//     this.underWritingStatus = this.decision.decisionStatus;
		// }
	}

	updateCollateralStatus(collateral: CollateralModel) {
		if (collateral === undefined || collateral === null) {
			this.isCollateralAvailable = this.isValuationCompleted = this.isVINAvailable = false;
			return;
		}
		this.collateralId = collateral.collateralId;
		this.isTradelineSelected = (collateral.tradeLineId > 0 || collateral.loanAmount > 0);
		this.isValuationCompleted = (collateral.valuationType === 'AUTOMATED') &&
			(collateral.status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED || collateral.status === ApplicationStatusEnum.COLLATERAL_PROPERTY_UPDATE);
		this.isVINAvailable = !(collateral.vin === undefined || collateral.vin === null || collateral.vin === '');
		this.isVehicleYearAvailable = !(collateral.year === undefined || collateral.year === null || collateral.year === '');
		this.isCollateralAvailable = (collateral.status === ApplicationStatusEnum.COLLATERAL_VALUE_RECEIVED ||
			collateral.status === ApplicationStatusEnum.COLLATERAL_REQUEST_FAILED) || this.isVINAvailable
			|| this.isVehicleYearAvailable;
		this.vehicleDescription = this.isValuationCompleted || this.isVehicleYearAvailable ? collateral.year + ' '
			+ collateral.make + ' ' + collateral.model + ' ' + collateral.body : '';
		this.offerGeneratedButtonText = this.isOfferGenerated ? 'Re-Generate Offer' : 'Generate Offer';
	}

	updateURLIdentifiers(obj: UrlObject) {
		this.urlObject = obj;
		const pathVariables = this.urlObject.pathname.split(/[/;?]+/);
		this.referralCode = '';
		console.log(pathVariables);
		if (typeof pathVariables === 'object' && pathVariables.length >= 2) {

			Object.keys(pathVariables).forEach(item => {
				switch (pathVariables[item].toLowerCase()) {
					case 'product':
						this.loanPurpose = pathVariables[Number(item)+1].toUpperCase();
						if (AppConfigService.config.loanPurpose.hasOwnProperty(this.loanPurpose)) {
							this.loanPurposeConfig = AppConfigService.config.loanPurpose[this.loanPurpose];
						}
						break;

					case 'partner':
						this.referralCode = pathVariables[Number(item)+1];
						break;

					case 'category':
						this.productName = pathVariables[Number(item)+1].toUpperCase();
						break;
					default:
						break;
				}
			})

			if(pathVariables[1] === '') {
				this.referralCode = pathVariables[2];
			}
			if (pathVariables.length > 3 && pathVariables[2] === 'product') {
				const loanPurpose = pathVariables[3].toUpperCase();
				if (AppConfigService.config.loanPurpose.hasOwnProperty(loanPurpose)) {
					this.loanPurpose = loanPurpose;
					this.loanPurposeConfig = AppConfigService.config.loanPurpose[loanPurpose];
				}
			}
		}
		this.subId = '';
		if (typeof this.urlObject === 'object' && typeof this.urlObject.query === 'object') {
			this.subId = this.urlObject.query.subID;
			if (this.subId === undefined || this.subId === null) {
				this.subId = this.urlObject.query.subId;
			}
			if (this.subId === undefined || this.subId === null) {
				this.subId = this.urlObject.query.subid;
			}
		}

		console.log(' lead and referral code =>', this.referralCode, this.subId);
	}

	setProvisioningStatus(application: ApplicationModel) {
		this.application = application;
		this.losStatus = application.losStatus;
		this.idVerificationStatus = application.idVerificationStatus;
		this.loanAgreementStatus = application.loanAgreementStatus;
		this.documentsStatus = application.documentsStatus;
	}

	updateApplicant(applicants: ApplicantModel[]) {
		if (applicants === undefined || applicants === null || applicants.length === 0) {
			return;
		}
		this.primaryApplicant = applicants[0];
		if (applicants.length > 1) {
			this.secondApplicant = applicants[1];
		}
		this.applicantFirstName = this.primaryApplicant.firstName;
		this.applicantLastName = this.primaryApplicant.lastName;
	}

	identifyProductType(loanPurpose: string): string {
		this.loanPurposeConfig = AppConfigService.config.loanPurpose[loanPurpose];
		const productName = this.loanPurposeConfig.productName.toLowerCase();
		this.isAutoLoanApplication = (productName === 'auto' || productName === 'auto_specialty');
		this.isCreditCardApplication = productName === 'credit_card';
		this.isPersonalLoanApplication = productName === 'other';
		this.isHomeEquityLoanApplication = productName === 'home';
		this.productType = this.loanPurposeConfig.productName;
		return this.productType;
	}
}
