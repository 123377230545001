import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError, Observable, switchMap, throwError} from 'rxjs';
import {KeyIdentifierModel} from '@creditsnap/data-models';
import {APP_ENV_CONFIG, AuthenticationService, SharedKeyDataService} from '../index';

@Injectable()
export class HttpAuthInterceptService implements HttpInterceptor {
    inflightAuthRequest: any = null;
    keyIdentifier: KeyIdentifierModel;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private sharedKeyDataService: SharedKeyDataService,
        @Inject(APP_ENV_CONFIG) private environment: any
    ) {
        sharedKeyDataService.keyIdentifier$.subscribe((data: KeyIdentifierModel) => {
            this.keyIdentifier = data;
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newHeaders = new HttpHeaders();
        newHeaders = newHeaders.append('Accept', 'application/json');
        newHeaders = newHeaders.append('Access-Control-Allow-Origin', '*');
        newHeaders = newHeaders.append('Institution-Id', this.environment.institutionId);

        if (this.keyIdentifier && this.keyIdentifier.sessionId) {
            newHeaders = newHeaders.append('sessionid', this.keyIdentifier.sessionId);
        }

        if (this.keyIdentifier && this.keyIdentifier.channel) {
            newHeaders = newHeaders.append('Channel', this.keyIdentifier.channel);
        } else {
            newHeaders = newHeaders.append('Channel', this.environment.channel);
        }

        if (this.keyIdentifier && this.keyIdentifier.employeeID) {
            newHeaders = newHeaders.append('EmployeeId', this.keyIdentifier.employeeID);
        }

        const hasContentType: boolean = request.headers.has('Content-Type');
        if (!hasContentType) {
            newHeaders = newHeaders.append('Content-Type', 'application/json; charset=utf-8');
        }
        // const hasIovationKey: boolean = request.headers.has('Iovation_Blackbox');
        // if (hasIovationKey) {
        //     newHeaders = newHeaders.append('Iovation_Blackbox', request.headers.get('Iovation_Blackbox'));
        // }

        const idPalHeader: boolean = request.headers.has('communication-type');
        if (idPalHeader) {
            newHeaders = newHeaders.append('Communication-Type', request.headers.get('Communication-Type'));
        }

        const skipAuthHeader: boolean = request.headers.has('X-Skip-Auth');
        if (skipAuthHeader) {
            newHeaders = newHeaders.append('X-Skip-Auth', request.headers.get('X-Skip-Auth'));
        }

        let basicAuthRequest = request.clone({headers: newHeaders});
        const hasSkipOAuth: boolean = request.headers.has(this.environment.auth.interceptorSkipHeader);
        if (hasSkipOAuth) {
            const isProspect: boolean = request.headers.has('Is-Prospect');
            if (isProspect) {
                newHeaders = newHeaders.append('Is-Prospect', 'Y');
            }
            const authCode: boolean = request.headers.has('authcode');
            if (authCode) {
                newHeaders = newHeaders.append('AUTHCODE', request.headers.get('authcode'));
            }
            basicAuthRequest = request.clone({headers: newHeaders});
            return next.handle(basicAuthRequest.clone({
                headers: basicAuthRequest.headers.set('Authorization', this.environment.auth.basic)
            }));
        }

        if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = this.authService.getToken();
        }
        return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
                // unset request inflight
                this.inflightAuthRequest = null;
                const token = newToken ? newToken : '';
                // console.log('before-=',basicAuthRequest.headers);

                basicAuthRequest = basicAuthRequest.clone({
                    headers: basicAuthRequest.headers.append('Authorization', `Bearer ${token}`)
                });
                if (basicAuthRequest.headers.has('X-Skip-Auth')) {
                    basicAuthRequest = basicAuthRequest.clone({
                        headers: basicAuthRequest.headers.delete('Authorization')
                    });
                }
                // console.log('after--',basicAuthRequest.headers);

                return next.handle(basicAuthRequest);
            }),
            catchError(error => {
                // checks if a url is to an admin api or not
                const appError = error.error || null;
                let invalid_grant = false;
                if (appError && appError.error) {
                    invalid_grant = !!appError.error.match(/invalid_grant/g);
                }
                this.inflightAuthRequest = null;
                // HTTP status code is 400 but application errr.code is > 0 then, this is a expected result
                if (error.status === 400 && appError && appError.code > 0) {
                    return throwError(error.error);
                } else if (error.status === 400 && invalid_grant) {
                    // HTTP status code is 400 but invalid grant error then force to relogin
                    //  console.log('timeout catchError 400 => ', (new Date()).getUTCMilliseconds(), error);
                    this.authService.logout(true);
                    this.router.navigate(['/myapp']);
                    return throwError(error);
                } else if (error.status === 401 || error.status === 0) {
                    // HTTP status code is 401 unauthorized, may be unable to renew refresh token
                    // check if the response is from the token refresh end point
                    const isRefreshTokenExpired = !!appError.error.match(/unauthorized/g) || !!appError.error_description.match(/expired/g) &&
                        !!appError.error_description.match(/invalid_token/g);
                    // console.log('timeout catchError 401 => ', (new Date()).getUTCMilliseconds(), error, isRefreshTokenExpired);
                    // || !!appError.error_description.match(/Login User Not Found/g);
                    if (isRefreshTokenExpired) {
                        this.authService.logout(true);
                        this.router.navigate(['/myapp']);
                        return throwError(error);
                    } else if (!this.inflightAuthRequest) {
                        this.inflightAuthRequest = this.authService.refreshToken();
                        if (!this.inflightAuthRequest) {
                            // remove existing tokens
                            // console.log('timeout catchError  inflightAuthRequest if redirect to login => ',
                            //    (new Date()).getUTCMilliseconds(), this.inflightAuthRequest);
                            this.authService.logout(true);
                            this.router.navigate(['/myapp']);
                            return throwError(error);
                        }
                    }
                    return this.inflightAuthRequest.pipe(
                        switchMap((newToken: string) => {
                            // unset inflight request
                            this.inflightAuthRequest = null;
                            return next.handle(basicAuthRequest.clone({
                                headers: basicAuthRequest.headers.set('Authorization', `Bearer ${newToken}`)
                            }));
                        })
                    );
                } else if (error.status === 419) {
                    return this.authService.refreshToken().subscribe(token => {
                        // console.log('timeout 2nd inflightAuthRequest 419 => ', (new Date()).getUTCMilliseconds(), token);
                        return next.handle(basicAuthRequest.clone({
                            headers: basicAuthRequest.headers.set('Authorization', `Bearer ${token}`)
                        }));
                    });

                } else {
                    // console.log('timeout catch last else => ', (new Date()).getUTCMilliseconds(), error);
                    return throwError(error);
                }
            }) as any
        );
    }
}
