import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgModel,
    ValidationErrors,
    Validator
} from '@angular/forms';
import * as moment from 'moment';


@Component({
    selector: 'creditsnap-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateComponent),
        multi: true
    }]
})
export class DateComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
    value: Date | string;
    dateOfBirth: Date;
    @Input() id: string;
    @Input() name: string;
    @Input() label = 'Date of Birth';
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() maxDate: Date;
    @Input() minDate: Date;
    @Input() optional: boolean = false;
    @Output() dateDataEvent: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() isValid = new EventEmitter<false>();
    @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
    @ViewChild('csDate') dateEleRef: NgModel | undefined;
    age = 0;

    validate(control: AbstractControl): ValidationErrors | null {
        return this.dateEleRef?.errors;
    }

    ngOnInit() {
        // if string convert to date object
        if (this.value) {
            this.dateOfBirth = new Date(this.value);
        }
        if (!this.maxDate) {
            this.maxDate = new Date();
        }
        this.age = moment().diff(this.maxDate, 'years');

        if (!this.minDate) {
            this.minDate = new Date();
            this.minDate.setFullYear(this.minDate.getFullYear() - 100);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minDate && changes.minDate.currentValue) {
            this.minDate = changes.minDate.currentValue;
        }

        if (changes.maxDate && changes.maxDate.currentValue) {
            this.maxDate = changes.maxDate.currentValue;
        }

        if (changes.optional && changes.optional.currentValue) {
            this.optional = changes.optional.currentValue;
        }
    }

    /**
     * Invoked when the model has been changed
     */
    onChange(_: Date | string) {
        console.log(_);
    }

    /**
     * Invoked when the model has been touched
     */
    onTouched: () => void = () => {
    }

    /**
     * Method that is invoked on an update of a model.
     */
    updateChanges() {
        this.onChange(this.value);
    }

    ///////////////
    // OVERRIDES //
    ///////////////

    /**
     * Writes a new item to the element.
     * @param value the value
     */
    writeValue(value: Date | string): void {
        this.value = value;
        this.dateOfBirth = value ? new Date(value) : null;
        setTimeout(() => {
            this.updateChanges();
        }, 100);

    }

    /**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChangeDate(event) {
        this.value = event.value;
        // this.writeValue(event.value);
        setTimeout(() => {
            this.updateChanges();
            this.valueChange.emit(this.dateOfBirth);
        }, 100);
    }

    touch() {
        this.dateEleRef.control.markAsTouched();
    }

}
